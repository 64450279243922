import React from 'react';
import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Layout from './components/Layout';
import Home from './components/Home';
import Projects from './components/Projects';
import About from './components/About';
import Helmet from 'react-helmet';

function App() {
  return (
    <div className="App">
      <Helmet>
        <title>Michael Camerato</title>
      </Helmet>
      <BrowserRouter>
        <Routes>
          <Route path="/" Component={Layout}>
            <Route path="/" Component={Home} />
            <Route path="/projects" Component={Projects} />
            <Route path="/about" Component={About} />
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
