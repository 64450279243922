import React from 'react'
import './About.css'

function About() {
  return (
    <div className="About">
      <div className="ProfileCardContainer">
        <div className="ProfileCard">
          <img src={require('../assets/Temp PFP.png')} alt="Profile" />
        </div>
      </div>
      <div className="AboutCard">
        <h1>About Me</h1>
        <p>
          Hi, I'm Michael! I am a 3rd-year undergrad student at Rochester Institute of Technology studying for a B.S. in Software Engineering.
          I started programming in 8th grade, and I've been in love with it ever since. My favorite thing about programming is how it allows me to
          take something that seems impossible at first and make it a reality; there's no better feeling than seeing something you've worked on come to life
          in front of you. I'm always looking to push myself further in the field of software engineering, and I eagerly look forward to seeing what my career
          has in store for me. I'm currently looking for a co-op for the summer of 2024, so if you're interested in working with me, don't hesitate to reach out!
          <br/><br/>
          Email: <a href="mailto:camerato91703@gmail.com">camerato91703@gmail.com</a>
          <br/>
          LinkedIn: <a href="https://www.linkedin.com/in/michael-camerato-879206222/" target="_blank">linkedin.com/in/michael-camerato-879206222</a>
        </p>
      </div>
    </div>
  )
}

export default About
