import React, { useEffect, useRef } from 'react';
import './Projects.css';

function Projects() {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          const target = entry.target as HTMLElement;
          if (entry.isIntersecting) {
            target.classList.add(
              target.classList.contains('COdd') ? 'slideInLeft' : 'slideInRight'
            );
          }
        });
      },
      { threshold: 0.1, }
    );
    const elements = ref.current ? ref.current.querySelectorAll('.ProjectCard') : [];
    elements.forEach((el: Element) => observer.observe(el));
    return () => observer.disconnect();
  }, []);  

  return (
    <div className="Projects" ref={ref}>
      <div className="ProjectsHeader">
        <h1>
          hover for more info!
        </h1>
        <p>
          for any questions, please reach out via <a className="aEmailLink" href="/about">email</a> <br />
          source code available upon request
        </p>
      </div>
      <div className="ProjectsContainer">

        {/* Spotify Stat Tracker */}
        <div className="ProjectCardContainer">
          <div className="ProjectCard COdd">
            <div className="ExpandedProjectTextBox ETOdd">
              <h2>What I'm Using/Learning</h2>
              <p>
                - React <br /> - Spotify API <br /> - JSON (storing listening data locally) <br />
                - HTML/CSS/TS <br /> - Bootstrap (compatibility) <br /> - Firebase (hosting) <br />
                - Handling user tokens from APIs <br /> - Analyzing data in bulk <br />
              </p>
            </div>
            <div className="ProjectTextBox TOdd">
              <h2>Spotify Stat Tracker</h2>
              <p>
                This is a web-app that I started that gives users the ability to track their listening habits on Spotify. It's
                built with React, and powered by the Spotify API. Through this project, I've learned a lot about working with APIs,
                handling requests and user tokens, and analyzing data. I have big plans for this project, but for the time being it's
                been put on hold while I work on other things.
              </p>
            </div>
            <div className="ProjectImage IOdd">
              <img src={require("../assets/SpotifyLogo.png")} alt="Spotify Logo" />
            </div>
          </div>
        </div>

        {/* STM32 Nucleo Board MIDI Player */}
        <div className="ProjectCardContainer">
          <div className="ProjectCard CEven">
            <div className="ProjectImage IEven">
              <img src={require("../assets/nucleo.png")} alt="STM32 Nucleo Board" />
            </div>
            <div className="ProjectTextBox TEven">
              <h2>STM32 Nucleo Board MIDI Player</h2>
              <p>
                Using STM32 Cube IDE, I programmed an STM32 Nucleo board to parse MIDI files and play them through an external speaker.
                Through this project, I furthered my knowledge of working with low-level programming languages. I also learned a lot about
                ADC/DAC and the STM HAL library. This was my first project working with both hardware and software, and I had a great time!
              </p>
            </div>
            <div className="ExpandedProjectTextBox ETEven">
              <h2>What I Used/Learned</h2>
              <p>
                - STM32 NUCLEO-L476RG <br /> - STM32 Cube IDE <br /> - TeraTerm (input/output) <br />
                - Low-level C programming <br /> - MIDI file parsing <br /> - ADC/DAC <br /> - Hardware interfacing <br />
                - Breadboarding
              </p>
            </div>
          </div>
        </div>

        {/* Birthstone Bands eShop */}
        <div className="ProjectCardContainer">
          <div className="ProjectCard COdd">
            <div className="ExpandedProjectTextBox ETOdd">
              <h2>What I Used/Learned</h2>
              <p>
                - Angular <br /> - Springboot <br /> - REST APIs <br /> - HTML/CSS/TS <br /> - MVC architecture <br />
                - Agile/Scrum Techniques <br /> - Code Coverage <br /> - Software Team Dynamics <br />
              </p>
            </div>
            <div className="ProjectTextBox TOdd">
              <h2>Birthstone Bands eShop</h2>
              <p>
                This was an e-commerce website that I worked on for a class with a team of 3 other students. I was the head of the
                front-end design, where I worked with different libraries and frameworks to achieve a beautiful, minimalistic
                user-interface. I put over 50 hours into this project, and the experience that it gave me was invaluable.
              </p>
            </div>
            <div className="ProjectImage IOdd">
              <img src={require("../assets/BirthstoneBands.png")} alt="BirthstoneBands" />
            </div>
          </div>
        </div>

        {/* JavaFX Chess Game */}
        <div className="ProjectCardContainer">
          <div className="ProjectCard CEven">
            <div className="ProjectImage IEven">
              <img src={require("../assets/JavaFX Chess.png")} alt="Chess" />
            </div>
            <div className="ProjectTextBox TEven">
              <h2>JavaFX Chess Game</h2>
              <p>
                Freshman year, I built a chess game using JavaFX, and it was a good introduction to OOP and working with GUIs.
                The most challenging part of this project was definitely implementing backtracking to check for checkmate,
                which helped me learn more about recursion and algorithms in general. In the end, the GUI came out clean and I
                was even able to implement more complex moves, such as castling.
              </p>
            </div>
            <div className="ExpandedProjectTextBox ETEven">
              <h2>What I Used/Learned</h2>
              <p>
                - Object-Oriented Programming <br/> - Debugging <br/> - JavaFX <br/> - GUI Design <br/>
                - Backtracking <br/> - Recursion <br/>
              </p>
            </div>
          </div>
        </div>

        {/* This Website :) */}
        <div className="ProjectCardContainer">
          <div className="ProjectCard COdd">
            <div className="ExpandedProjectTextBox ETOdd">
              <h2>What I Used/Learned</h2>
              <p>
                - React <br/> - TSX/CSS/TS <br/> - Google Firebase <br/> - Hosting <br/>
                - React File/Folder Architecture <br/> - NPM
              </p>
            </div>
            <div className="ProjectTextBox TOdd">
              <h2>This Website :)</h2>
              <p>
                This website was built to showcase my projects. I used React with TSX/CSS/TS, and built it from the ground up
                with no templates. As it isn't as advanced as my other projects, I had more time to focus on minimalism.
                I used Google Firebase along with React, and while it was difficult at first working with the hosting,
                I eventually got everything working and I am proud with how it turned out.
              </p>
            </div>
            <div className="ProjectImage IOdd">
              <img src={require("../assets/React + Firebase.png")} alt="React and Firebase" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Projects;
