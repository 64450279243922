import React, { useEffect, useRef } from 'react';
import './Home.css';

function Home() {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add('fade-in');
          }
        });
      },
      { threshold: 0.05, }
    );
    const elements = ref.current ? ref.current.querySelectorAll('.observe') : [];
    elements.forEach((el: Element) => observer.observe(el));
    return () => observer.disconnect();
  }, []);

  return (
    <div className="Home" ref={ref}>
      <p className="line1 observe">Hello!</p>
      <p className="line2 observe">I'm Michael Camerato</p>
      <p className="line3 observe">I'm an aspiring software engineer in my third year of university, with a passion for problem-solving, analytical thinking, and hard work.</p>
      <p className="line4 observe">Below are some of the skills that I pride myself on.</p>
      <div className="Skills observe">
        <div className="HomeCard">
          <div className="HomeCardText">
            <h3>Problem Solving</h3>
            <p>I love solving problems, whether it's a coding challenge, a puzzle, or a real-world problem.</p>
          </div>
        </div>
        <div className="HomeCard">
          <div className="HomeCardText">
            <h3>Teamwork</h3>
            <p>I enjoy working with others, and I believe that the best solutions come from collaboration.</p>
          </div>
        </div>
        <div className="HomeCard">
          <div className="HomeCardText">
            <h3>Communication</h3>
            <p>I'm a strong communicator, and I believe that clear communication is essential to success.</p>
          </div>
        </div>
      </div>
      <p className="line5 observe">In my time at university, I've been able to familiarize myself with a variety of <br/> programming languages, libraries, and software, including:</p>
      <div className="Experiences observe">
        <div className="HomeCard">
          <div className="HomeCardText">
            <h3>Languages</h3>
            <p>C, C++, Java, Python, HTML, CSS (and SCSS), JavaScript, TypeScript, SQL, LaTeX</p>
          </div>
        </div>
        <div className="HomeCard">
          <div className="HomeCardText">
            <h3>Tools</h3>
            <p>GitHub, Google Colab, Google Firebase, JUnit, React, Angular, Node.js, Springboot, Bootstrap, Sklearn, Pandas, Numpy, Matplotlib, ThreeJS, and more!</p>
          </div>
        </div>
        <div className="HomeCard">
          <div className="HomeCardText">
            <h3>Software</h3>
            <p>VS Code, Eclipse, Jupyter, Docker, STM32 Cube IDE, Tera Term, MySQL, PostgreSQL, MongoDB</p>
          </div>
        </div>
      </div>
      <p className="line6 observe">I'm always looking for new opportunities to learn and grow, and I'm excited <br/> to see where my career takes me.</p>
      {/* make the word "projects" link to the projects component */}
      <p className="line7 observe">Head on over to the <a href="/projects">projects</a> section to see what I've been up to!</p>
    </div>
  );
}

export default Home;
