import React from 'react';
import './Layout.css';
import { Outlet, Link } from 'react-router-dom';

function Layout() {
  return (
    <div className="Layout">
      <nav>
        <img src={require("../assets/logo.png")} alt="Logo"/>
        <Link className="Link" to="/">Home</Link>
        <Link className="Link" to="/projects">Projects</Link>
        <Link className="Link" to="/about">About</Link>
        <p><a className="EmailLink" href="mailto:camerato91703@gmail.com">camerato91703@gmail.com</a></p>
      </nav>
      <Outlet />
    </div>
  );
}

export default Layout;
